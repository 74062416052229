interface CheckinItem {
    id: number;
    label: string;
    key: any;
    checkin_id: number;
    user: {
        user_name: string;
    }
    beer: {
        beer_label: string;
    }
    media: {
        count: number;
        items: [{
            photo_img_sm: string;
            photo_img_md: string;
            photo_img_lg: string;
            photo_img_og: string;
        }];
    };
    toasts: {
        total_count: number;
        count: number;
    }
}

interface CheckinsList extends Array<CheckinItem>{}

class Checkins {
    untappdId: string
    isLoaded: boolean;
    raw: CheckinsList;

    constructor(untappdId?: string) {
        if (untappdId) this.untappdId = untappdId;
    }
    
    async fetch() {
        if (!this.untappdId) return Promise.resolve(this);
        return new Promise((resolve, reject) => {
            fetch('https://services.smerty.org/api/untappd/beer/' + this.untappdId + '/checkins')
                .then(response => response.json())
                .then(json => {
                    this.isLoaded = true;
                    this.raw = json;
                    resolve(this);
                });
        });
    }
}

export default Checkins;
