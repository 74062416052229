interface BatchRaw {
    recipe: {
        name: string;
        style: {
            name: string;
        }
        thumb: string;
        fermentables: [{
            name: string;
            amount: string;
        }]
        hops: [{
            name: string;
            amount: string;
        }]
        yeasts: [{
            productId: string;
            laboratory: string;
            name: string;
        }]
    };
    measuredAbv: string;
    measuredOg: string;
    measuredFg: string;
    estimatedIbu: string;
}

class Batch {
    brewfatherId: string;
    isLoaded: boolean;
    raw: BatchRaw;
    
    constructor(brewfatherId?: string, untappdId?: string) {
        if (brewfatherId) this.brewfatherId = brewfatherId;
    }
    
    async fetch() {
        if (!this.brewfatherId) return Promise.resolve(this);
        return new Promise((resolve, reject) => {
            fetch('https://services.smerty.org/api/brewfather/batches/' + this.brewfatherId)
                .then(response => response.json())
                .then(json => {
                    this.isLoaded = true;
                    this.raw = json;
                    resolve(this);
                });
        });
    }
}

export default Batch;
