import * as React from 'react';
import ReactDOM from 'react-dom';
import Beer from './components/beer';

class App extends React.Component {
    state: {
        loading: boolean;
    };
    
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
        }
    }
    
    componentDidMount() {
    }
    
    render() {
        if (this.state.loading) {
            return <h1>Loading....</h1>;

        }

        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'center' }}>
                <Beer brewfatherId='SbmpVV5fKoMwVKOklP3xJa1RNu93tc' untappdId={ '3922757' } />
                <Beer brewfatherId='OqHbopHqKAWAj1buZ8YsstOKjpDuUD' untappdId={ '4017276' } />
                <Beer brewfatherId='0Ni2QaCbynoosuaZjvNeY1ljaLPhP6' untappdId={ '4067110' } />
                <Beer brewfatherId='2qZBFyM9SzTNTL60o7fF5Ys8O5Hwdi' untappdId={ null } />
                <Beer brewfatherId='vPglQWpCccygcu3FYh8FpmlGeWNmu3' untappdId={ '3810520' } />
            </div>
        );
    }
}

export default App;