import * as React from 'react';
import ReactDOM from "react-dom";
import BrewfatherBatchModel from '../../models/brewfather/batch';
import UntappedCheckinsModel from '../../models/untappd/checkins';

class Beer extends React.Component {
    state: {
        loading: boolean;
        brewfatherBatchModel: BrewfatherBatchModel;
        untappdCheckinsModel: UntappedCheckinsModel;
    };
    
    props: {
      brewfatherId: string; 
      untappdId: string;
    };
    
    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            brewfatherBatchModel: new BrewfatherBatchModel(props.brewfatherId),
            untappdCheckinsModel: new UntappedCheckinsModel(props.untappdId),
        }
    }
    
    componentDidMount() {
        const promise = this.state.brewfatherBatchModel.fetch();
        const promise2 = this.state.untappdCheckinsModel.fetch();
        
        Promise.all([promise, promise2]).then(() => {
            this.setState({
                loading: false,
            })
        })
    }
    
    render() {
        if (this.state.loading) {
            return <h1>Loading....</h1>;

        }
        
        /*
        const checkItem = (checkin) => {
            return (
                <a target="_blank" rel="noopener noreferrer" href={"https://untappd.com/user/" + checkin.user.user_name + "/checkin/" + checkin.checkin_id}>
                    <img className={classes.large} src={checkin.media.items[0].photo.photo_img_sm} />
                </a>
            )
        }
        */
        const checkItem = (checkin) => {
            let photoUrl;
            console.log(checkin);
            if (checkin.media.count > 0) {
                photoUrl = checkin.media.items[0].photo.photo_img_sm;
            } else {
                photoUrl = checkin.beer.beer_label;
            }
            return <a target='_blank' href={`https://untappd.com/user/${checkin.user.user_name}/checkin/${checkin.checkin_id}`}><img width={64} height={64} src={ photoUrl } title={ checkin.toasts.count } /></a>
        }
        
        const fermentableItem = (fermentable) => {
            return (
                <div>{fermentable.name} {Math.round(fermentable.amount / 0.45359237 * 100) / 100} lbs ({fermentable.percentage}%)</div>
            )
        };
        
        const hopItem = (hop) => {
            return (
                <div>{hop.name} {Math.round(hop.amount / 28.3495 * 100) / 100} oz {hop.time} {hop.use}</div>
            )
        };
        
        const yeastItem = (yeast) => {
            return (
                <div>{yeast.laboratory} {yeast.productId} {yeast.name}</div>
            )
        };

        const brewfather = this.state.brewfatherBatchModel.raw;
        
        let beerLabel = brewfather.recipe.thumb;
        
        let checkins;
        if (this.state.untappdCheckinsModel.isLoaded) {
            if (this.state.untappdCheckinsModel.raw[0].beer.beer_label) {
                beerLabel = this.state.untappdCheckinsModel.raw[0].beer.beer_label;
            }
            checkins = (
                <div>
                    <div><b>Checkins:</b></div>
                    <div>
                        { this.state.untappdCheckinsModel.raw.map(checkItem) }
                    </div>
                </div>
            );
        }
        
        return (
            <div style={{ width: '320px', borderStyle: 'solid', borderWidth: '1px', borderColor: 'lightgray', margin: '3px', padding: '5px' }}>
                <img src={ beerLabel } />
                <div><b style={{ textDecoration: 'underline' }}>{ brewfather.recipe.name }</b></div>
                <div>Style: { brewfather.recipe.style.name }</div>
                <div><b>Stats</b></div>
                <div>ABV: { brewfather.measuredAbv }%</div>
                <div>OG: { brewfather.measuredOg }</div>
                <div>FG: { brewfather.measuredFg }</div>
                <div>IBU: { brewfather.estimatedIbu }</div>
                <div>
                    <div><b>Fermentables:</b></div>
                    { brewfather.recipe.fermentables.map(fermentableItem) }
                </div>
                <div>
                    <div><b>Hops:</b></div>
                    { brewfather.recipe.hops.map(hopItem) }
                </div>
                <div>
                    <div><b>Yeast:</b></div>
                    { brewfather.recipe.yeasts.map(yeastItem) }
                </div>
                { checkins }
                <br />
            </div>
        );
    }
}

export default Beer;